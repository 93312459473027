const level = {
	"dimension":15, 
	"floor":[
        [7,7],[6,11],[7,11],[8,11],[8,12],[8,13],[7,13],[6,13],[6,12],[7,12],[5,11],[4,13],
        [5,12],[5,13],[9,13],[9,12],[9,11],[10,12],[11,12],[12,12],[4,12],[3,12],[2,12],
        [13,12],[13,13],[12,13],[12,11],[12,10],[12,9],[12,7],[12,8],[12,6],[2,11],[2,13],
        [1,13],[1,12],[1,11],[2,10],[2,9],[13,11],[2,8],[2,7],[3,7],[4,7],[5,7],[6,7],[8,7],
        [9,7],[10,7],[11,7],[10,6],[9,6],[11,6],[8,6],[7,6],[6,6],[5,6],[4,6],[3,6],[2,6],
        [7,5],[7,4],[7,3],[8,3],[6,3],[5,3],[9,3],[9,2],[8,2],[7,2],[6,2],[5,2],[11,11],[4,11]
    ], 
	"startBoxes":[[3,12],[10,12],[5,7],[7,7],[9,7],[3,7],[11,7],[6,3],[8,3]], 
	"endBoxes":[[7,11],[6,11],[8,11],[8,12],[8,13],[7,13],[6,13],[6,12],[7,12]], 
	"player":[7,13]
};

export default level;