const level = {
	"dimension":15, 
	"floor":[
        [7,7],[7,1],[7,2],[7,3],[8,3],[6,3],[7,4],[7,5],[7,6],[8,7],[9,7],[6,7],[5,7],
        [4,7],[10,7],[11,8],[10,8],[11,9],[10,9],[11,10],[10,10],[4,8],[4,9],[3,8],[3,9],
        [7,9],[6,9],[6,10],[5,10],[8,9],[8,10],[9,10],[10,11],[10,12],[11,12],[3,10],[4,10],
        [4,11],[4,12],[3,12],[2,12],[12,12],[13,12],[13,13],[10,13],[1,12],[1,13],[4,13],
        [2,13],[3,13],[11,13],[12,13],[11,11],[3,11],[8,4],[6,4],[8,5],[6,5],[7,8],[3,7],[11,7],[8,2],[6,2]
    ], 
	"startBoxes":[[2,12],[12,12],[4,9],[10,9],[10,12],[11,9],[4,12],[3,9]], 
	"endBoxes":[[7,2],[7,1],[6,3],[7,3],[8,3],[7,4],[8,2],[6,2]], 
	"player":[7,2]
};

export default level;