const level = {
	"dimension":15, 
	"floor":[
        [5,10],[6,10],[8,10],[9,10],[10,10],[9,11],[10,12],[9,12],[8,11],[8,12],
        [7,12],[7,11],[6,12],[6,11],[5,11],[4,12],[5,12],[4,11],[4,10],[11,10],[12,10],
        [12,9],[11,9],[11,8],[11,7],[12,7],[12,8],[12,6],[11,6],[11,5],[12,5],[12,4],
        [11,4],[11,3],[12,3],[12,2],[11,2],[13,2],[13,3],[10,3],[9,3],[8,3],[7,3],[6,3],
        [5,3],[7,4],[7,5],[7,6],[4,3],[3,3],[7,2],[7,1],[9,1],[8,1],[10,1],[11,1],[3,4],
        [3,5],[2,3],[2,4],[2,5],[3,6],[2,6],[1,5],[1,6],[12,11],[12,12],[11,12],[7,10],[1,4],
        [6,1],[5,1],[4,1],[3,1],[2,1],[2,2]
    ], 
	"startBoxes":[[11,9],[12,7],[11,5],[12,3],[8,10],[8,11],[6,11],[6,10]], 
	"endBoxes":[[7,4],[7,5],[7,6],[1,4],[1,5],[1,6],[3,6],[2,6]], 
	"player":[7,12]
};

export default level;