const level = {
	"dimension":15, 
	"floor":[
        [7,1],[6,1],[6,2],[7,2],[8,2],[8,1],[6,3],[7,3],[8,3],[1,6],[2,6],[3,6],
        [3,7],[2,7],[1,7],[1,8],[2,8],[11,6],[12,6],[13,6],[13,7],[12,7],[11,7],
        [11,8],[12,8],[13,8],[7,11],[7,12],[8,12],[8,13],[7,13],[6,13],[6,12],[6,4],
        [6,5],[6,6],[5,6],[4,6],[7,6],[8,6],[9,6],[10,6],[4,5],[5,4],[4,4],[5,3],[11,9],
        [10,9],[10,10],[9,10],[9,11],[3,9],[3,10],[3,11],[5,11],[4,11],[6,11],[8,11],
        [3,8],[1,9],[1,5],[3,4],[2,4],[2,5]
    ], 
	"startBoxes":[[6,2],[7,2],[12,7],[8,12],[6,12],[3,9],[3,6]], 
	"endBoxes":[[5,11],[9,11],[1,8],[1,6],[1,7],[1,9],[1,5]], 
	"player":[7,1]
};

export default level;