const level = {
	"dimension":15, 
	"floor":[
            [7,7],[2,12],[2,11],[2,10],[2,8],[2,9],[2,7],[2,6],[2,5],[2,4],
            [2,3],[2,2],[3,2],[4,2],[5,2],[6,2],[7,2],[8,2],[9,2],[10,2],
            [11,2],[12,2],[12,3],[12,4],[12,5],[12,7],[12,6],[12,8],[12,9],
            [12,10],[12,11],[12,12],[11,12],[10,12],[9,12],[8,12],[7,12],
            [6,12],[5,12],[4,12],[3,12],[7,11],[7,10],[7,9],[7,8],[3,3],[4,3],
            [3,4],[4,4],[11,3],[10,3],[10,4],[11,4],[11,11],[10,11],[10,10],
            [11,10],[3,11],[3,10],[4,10],[4,11],[1,3],[1,4],[13,3],[13,4],
            [10,1],[4,1],[9,4],[5,4]
        ], 
	"startBoxes":[[2,8],[3,11],[12,8],[11,11]], 
	"endBoxes":[[4,1],[10,1],[9,4],[5,4]], 
	"player":[7,7]
};

export default level;