const level = {
    floor: [
        [7,4],[7,5],[7,6],[7,7],[7,8],[7,9],[7,10],
        [4,7],[5,7],[6,7],[8,7],[9,7],[10,7]
    ],
    startBoxes: [
        [7,6],[7,8],[6,7],[8,7]
    ],
    endBoxes: [
        [7,4],[7,10],[4,7],[10,7]
    ],
    player: [7,7]
};

export default level;

