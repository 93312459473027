const level = {
	"dimension":15, 
	"floor":[
        [7,7],[7,13],[7,12],[7,11],[7,10],[7,9],[7,8],[7,6],[7,5],[8,4],[9,3],[10,2],
        [11,1],[12,1],[13,1],[6,4],[5,3],[6,2],[5,1],[4,1],[5,2],[6,3],[7,4],[9,4],
        [10,3],[11,2],[6,7],[5,6],[4,6],[4,5],[3,4],[3,5],[2,4],[2,5],[8,5],[5,7],[8,9],
        [9,8],[8,8],[9,7],[9,9],[3,6],[1,5],[1,4],[7,3],[7,2],[8,2],[9,2],[3,1],[6,1],
        [2,1],[10,1],[11,3]
    ], 
	"startBoxes":[[3,5],[7,10],[8,8],[8,4],[5,2],[9,2]], 
	"endBoxes":[[4,5],[9,7],[2,1],[3,1],[13,1],[12,1]], 
	"player":[7,13]
};

export default level;