const level = {
	"dimension":15, 
	"floor":[
            [7,7],[3,11],[3,10],[3,9],[3,8],[3,7],[3,6],[3,5],[3,4],[3,3],
            [3,2],[4,2],[5,2],[5,3],[5,4],[4,4],[5,5],[5,6],[5,7],[4,5],[5,8],
            [5,9],[5,10],[5,11],[6,7],[8,7],[9,7],[9,6],[9,5],[10,5],[12,5],[11,5],
            [12,6],[12,7],[12,8],[12,9],[11,9],[10,8],[9,8],[9,9],[10,9],[11,8],[10,7],
            [11,6],[10,6],[4,9],[4,10],[3,12],[5,12],[4,12],[11,7],[4,7],[13,6],[13,7],[13,8]
        ], 
	"startBoxes":[[4,10],[4,9],[4,5],[4,4],[4,7]], 
	"endBoxes":[[10,6],[11,6],[11,8],[10,8],[11,7]], 
	"player":[7,7]
};

export default level;