const level = {
    floor: [
        [3,5],[4,5],[5,5],[6,5],[7,5],[8,5],[9,5],[10,5],[11,5],
        [3,6],[4,6],[5,6],[6,6],[7,6],[8,6],[9,6],[10,6],[11,6],
        [3,7],[4,7],[5,7],[6,7],[7,7],[8,7],[9,7],[10,7],[11,7],
        [3,8],[5,8],[7,8],[9,8],[11,8],
        [3,9],[4,9],[5,9],[6,9],[7,9],[8,9],[9,9],[10,9],[11,9]
    ],
    startBoxes: [
        [5,8],[7,8],[9,8]
    ],
    endBoxes: [
        [6,6],[7,6],[8,6]
    ],
    player: [7,5]
};

export default level;

